<template>
    <el-card class="margin-t content no-shadow no-border">
        <div :class="{ 'mock-card-body': isMock }">

            <div v-if="tag == 'RO'">
                <draggable :move="draggableMoe" v-model="content" draggable=".drage-item">
                    <div v-for="(item, index) in content" :key="index"
                        :class="submitted == 1 ? (result.length > 0 ? 'margin-x' : 'margin') : 'margin drage-item'">
                        <el-card style="cursor:move;">
                            <div class="mt tj flex flex-v-center flex-wrap  flex flex-v-center flex-wrap bg-white">
                                <h3 class="magin-x">{{ item }}:</h3>
                                <span v-word :class="{ 'word': !isMock, 'word-mock': isMock }"
                                    v-for="(word, index1) in options[item - 1] ? options[item - 1].words : []"
                                    :key="index1">{{
                                        word
                                    }}</span>
                                <!-- <i v-if="submitted == 1 && result[index].split('/')[1] == 1"
                            style="color:green;font-weight:bold;" class="el-icon-check"></i>

                        <i v-if="submitted == 1 && result[index].split('/')[1] == 0" style="color:red;font-weight:bold;"
                            class="el-icon-close"></i> -->
                            </div>
                        </el-card>
                        <div class="flex flex-v-center flex-column"
                            v-if="submitted == 1 && (index < result.length - 1) && result[index].split('/')[1] == 1">
                            <div class="v-line"></div>
                            <div style="height:20px;width:20px;border-radius:50%;border:2px solid green;"
                                class="flex flex-h-center flex-v-center"> <i style="color:green;font-weight:bold;"
                                    class="el-icon-check"></i></div>
                            <div class="v-line"></div>
                        </div>

                        <div class="flex flex-v-center flex-column"
                            v-if="submitted == 1 && (index < result.length - 1) && (result[index].split('/')[1] != 1)">
                            <div class="v-line"></div>
                            <div style="height:20px;width:20px;border-radius:50%;border:2px solid red;"
                                class="flex flex-h-center flex-v-center"> <i style="color:red;font-weight:bold;"
                                    class="el-icon-close"></i></div>
                            <div class="v-line"></div>
                        </div>
                    </div>



                </draggable>
            </div>

            <div v-else class="mt tj flex flex-v-center flex-wrap">
                <div v-for="(word, index) in words" :key="index">
                    <div v-if="word.options" class="flex flex-v-center select"
                        :class="{ 'right': submitted == 1 && content[word.key] == answers[word.key], 'wrong': submitted == 1 && content[word.key] != answers[word.key] }">
                        <el-select :clearable="submitted == 0" placeholder="" size="small"
                            :style="{ width: (word.maxLen * 10) + 'px' }" style="min-width:160px; margin:0 5px;"
                            v-model="content[word.key]">
                            <el-option :disabled="submitted == 1" v-for="(item, index1) in word.options" :key="index1"
                                :value="item">
                                <h4>{{ item }}</h4>
                            </el-option>
                        </el-select>
                        <i v-if="submitted == 1 && content[word.key] == answers[word.key]"
                            style="color:green;font-weight:bold;" class="el-icon-check"></i>
                        <div v-word v-if="submitted == 1 && content[word.key] != answers[word.key]"
                            style="color:red;font-weight:bold;">({{ answers[word.key] }})</div>

                        <i v-if="submitted == 1" @click="showA(word.key)" style="color: #e6a13c;font-size:20px ;"
                            class="el-icon-question pointer"></i>

                    </div>

                    <div v-else-if="word.draggable" class="flex flex-v-center">
                        <draggable :move="draggableMoe" v-model="content[word.index]" :sort="true" group="blank"
                            class="flex flex-v-center flex-h-center blank"
                            :class="{ 'right': submitted == 1 && content[word.index][0] == answers[word.index], 'wrong': submitted == 1 && content[word.index][0] != answers[word.index] }">
                            <el-tag v-if="content[word.index][0]" effect="dark" class="margin">
                                <span>{{ content[word.index][0] }} </span>
                            </el-tag>
                        </draggable>
                        <i v-if="submitted == 1 && content[word.index][0] == answers[word.index]"
                            style="color:green;font-weight:bold;" class="el-icon-check"></i>

                        <div v-word v-if="submitted == 1 && content[word.index][0] != answers[word.index]"
                            style="color:red;font-weight:bold;margin-left: 5px;">({{ answers[word.index] }})</div>

                        <i v-if="submitted == 1" @click="showA(word.index + 1)" style="color: #e6a13c;font-size:20px ;"
                            class="el-icon-question pointer"></i>
                    </div>

                    <h4 v-word :class="[isMock ? 'word-mock' : 'word']" v-else>
                        {{ word }}
                    </h4>
                </div>
            </div>

            <div v-if="tag == 'MCM-R' || tag == 'MCS-R'">
                <el-divider></el-divider>
                <div class="lt margin-y bold" v-if="source.question"> <span
                        v-for="(word, index) in source.question.replace(/\s+/g, ' ').split(' ')" :key="index"
                        :class="isMock ? 'word-mock' : 'word'"
                        :style="{ 'letter-spacing': word.indexOf('_') != -1 ? '0' : '1px' }" v-word>{{ word }}</span>
                </div>
            </div>

            <el-checkbox-group :class="{ 'mock-checkbox': isMock }" :disabled="submitted == 1" v-if="tag == 'MCM-R'"
                v-model="content">
                <div v-for="(words, index) in options" :key="index" class="flex flex-v-center">
                    <el-checkbox class="margin-t" :label="choice[index]">
                        <span v-for="(word, index1) in words" :key="index1"
                            :class="{ 'right': submitted == 1 && (content.indexOf(choice[index]) != -1 || answers.indexOf(choice[index]) != -1), 'wrong': submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) == -1, 'choose': submitted == 1 && content.indexOf(choice[index]) != -1, 'word': !isMock, 'word-mock': isMock }">{{
                                word }}</span>
                        <!-- <i v-if="(submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) != -1) || (view_flag && answers.indexOf(choice[index]) != -1)"
                        style="color:green;font-weight:bold;" class="el-icon-check"></i>

                    <i v-if="submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) == -1"
                        style="color:green;font-weight:bold;" class="el-icon-close"></i> -->
                    </el-checkbox>
                </div>
            </el-checkbox-group>

            <div class="mt tj flex flex-v-center flex-wrap" v-if="tag == 'FIB-R'">
                <el-divider></el-divider>
                <draggable :move="draggableMoe" v-model="options" group="blank" class="flex"
                    style="background:#f0f0f0; border-radius:10px">
                    <transition-group class="flex flex-wrap  flex1">
                        <el-tag class="pointer margin" effect="dark" v-for="(words, index) in options"
                            :key="'key' + index">
                            {{ words }}
                        </el-tag>
                    </transition-group>
                </draggable>
            </div>

            <div v-if="tag == 'MCS-R'" :class="{ 'mock-checkbox': isMock }">
                <div v-for="(words, index) in options" :key="index">
                    <el-radio :disabled="submitted == 1" class="margin-t" :label="choice[index]" v-model="content[0]">
                        <span v-for="(word, index1) in words" :key="index1"
                            :class="{ 'right': submitted == 1 && (content.indexOf(choice[index]) != -1 || answers.indexOf(choice[index]) != -1), 'wrong': submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) == -1, 'choose': submitted == 1 && content.indexOf(choice[index]) != -1, 'word': !isMock, 'word-mock': isMock }">{{
                                word
                            }}
                        </span>
                        <!-- <i v-if="(submitted == 1 && (content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) != -1)) || (answers.indexOf(choice[index]) != -1 && view_flag)"
                        style="color:green;font-weight:bold;" class="el-icon-check check-icon"></i>

                    <i v-if="submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) == -1"
                        style="color:red;font-weight:bold;" class="el-icon-close check-icon"></i> -->
                    </el-radio>
                </div>
            </div>
        </div>

        <analysis-popup ref="analysis"></analysis-popup>
    </el-card>
</template>

<script>
import draggable from 'vuedraggable'
import analysisPopup from './AnalysisPopup'
export default {
    components: {
        draggable, analysisPopup
    },

    name: "Reading",
    data() {
        return {
            time: 0,
            time_percentage: 0,
            status: 0,
            content: [],
            words: [],
            options: [],
            on_time: 0,
            choice: ["A", "B", "C", "D", "E", "F", "G"],
            submitted: 0,
            view_flag: false,
            answers: [],
            result: [],
            lastContent: null,
            options0: [],
            flag: -1,
        };
    },

    watch: {
        content: function (val) {
            if (this.tag == "FIB-R") {
                this.content.forEach((item, index) => {
                    if (item.length == 2) {
                        if (item[0] == this.lastContent[index][0]) {
                            item[0] = item.splice(1, 1)[0];
                        } else {
                            item[0] = item.splice(0, 1)[0];
                        }
                    }
                })
                this.lastContent = JSON.parse(JSON.stringify(val));

                let options = [];
                let index0 = -1;
                this.options0.forEach((item) => {
                    index0 = this.lastContent.findIndex((val) => {
                        return val[0] == item;
                    })
                    if (index0 == -1) {
                        options.push(item)
                    }
                })

                this.options = options;

            }

            let flag = true;
            if (this.tag == "FIB-R") {
                flag = true;
            } else if (this.tag == "FIB-RW") {
                flag = true;
            } else {
                val.forEach((item) => {
                    if ((typeof item == "object" && item.length == 0) || !item) {
                        flag = false;
                    }
                })
            }
            if (flag) {
                this.$bus.$emit("content", val);
            } else {
                this.$bus.$emit("content", null);
            }
        },
        source: function () {
            this.reDo();
        },
    },

    props: {
        source: {
            type: Object,
            default: () => {
                return {};
            },
        },
        tag: {
            type: String,
            default: "SWT",
        },
        isMock: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.$bus.$on("submit", (flag, result) => {
            this.submitted = flag;
            if (result) {
                if (this.tag == "RO") {
                    this.result = result.content.split(",");
                }
            }
        })

        this.$bus.$on("view_answer", flag => {
            if (flag) {
                this.view_flag = true;
            } else {
                this.view_flag = false;
            }
        })
        this.reDo();
    },
    methods: {


        showA(index) {
            this.$refs.analysis.popup(index, this.source)
        },

        draggableMoe() {
            return this.submitted == 0 ? true : false
        },

        addItem(val) {
            console.log(val)
        },

        getContent() {
            return this.content;
        },

        reDo() {
            if (this.$refs.analysis) this.$refs.analysis.close();
            this.submitted = 0;
            let source = this.source;
            this.content = [];
            this.options = [];
            this.options0 = [];
            if (source) {
                let info = this.$store.getters.getType(this.tag);
                this.in_time = info.in_time;
                this.on_time = info.on_time;

                if (this.status == 0) {
                    this.time = this.on_time;
                } else {
                    this.timer(this.on_time);
                }


                this.answers = source.answer.split(",");

                let that = this;
                if (this.tag == "FIB-RW" || this.tag == 'FIB-R') {
                    this.words = source.question.replace(/\s+/g, ' ').replace(/\s+\//g, '/').split(' ');
                } else {
                    this.words = source.words_en.replace(/\s+/g, ' ').replace(/\s+\//g, '/').split(' ');
                }

                let text = null;

                let key = 0;
                let seq = 0;

                this.words.forEach((word, index) => {
                    if (word.indexOf("(") == 0) {
                        if (that.tag == 'FIB-RW' && word.indexOf("/") != -1) {
                            that.content.push("");
                            text = word.replace("(", "").replace(")", "");

                            let options = [];
                            let maxLen = 0;
                            let option = null;
                            text.split("/").forEach((dd) => {
                                option = dd.replaceAll("_", " ");
                                options.push(option);
                                maxLen = maxLen > option.length ? maxLen : option.length;
                            })
                            that.words[index] = { key: key++, options: options, maxLen: maxLen };

                        } else if (that.tag == 'FIB-R' && /\(\d\)/.test(word)) {
                            that.content.push([]);
                            that.words[index] = { index: seq++, draggable: true };
                        }
                    }
                });
                if (source.options) {
                    let rows = source.options.replace(/\n+/g, '\n').split('\n');
                    if (that.tag == 'RO') {
                        rows.forEach((row, index) => {
                            that.content.push(index + 1)
                            that.options.push({ index: index + 1, words: row.replace(/\s+/g, ' ').replace(/\d+:/, '').replaceAll("·", ".").split(" ") });
                        })
                    } else if (that.tag == 'FIB-R') {
                        rows.forEach((row) => {
                            that.options.push(row.trim());
                            that.options0.push(row.trim());
                        })
                    } else {
                        rows.forEach((row) => {
                            that.options.push(row.trim().replace(/\s+/g, ' ').split(" "));
                        })
                    }

                }
                this.timer(this.on_time);
            }
        },

        format_time(time) {
            let minute = parseInt(time / 60);
            let second = time - minute * 60;
            return (minute < 10 ? `0${minute}` : minute) + ":" + (second < 10 ? `0${second}` : second);
        },

        timer(time) {
            if (time != undefined) this.time = time;
            let that = this;
            setTimeout(() => {
                if (this.status == 0) {
                    if (that.time > 0) {
                        that.time--;
                        that.timer();
                        that.time_percentage = parseInt((that.on_time - that.time) / that.on_time * 100);
                    } else {
                        this.status = 1;
                    }
                }
            }, 1000);
        },
    },
};
</script>
<style scoped>
.word.right {
    color: green;
}

.word.wrong {
    color: red;
}

.select>>>.el-input__inner {
    color: #e6a13c;
    font-weight: bold;
    font-size: 16px;
}

.select.wrong>>>.el-input__inner {
    border-color: red !important;
    font-weight: bold;
    font-size: 16px;
    color: red;
}



.content>>>.el-radio__inner {
    border-color: #666666 !important;
}

.select.right>>>.el-input__inner {
    border-color: green !important;
    font-weight: bold;
    font-size: 16px;
    color: green;
}

.content>>>.el-checkbox__label {
    font-size: 16px;
}

.content>>>.el-radio,
.content>>>.el-checkbox {
    display: flex;
    align-items: center;
}

.content>>>.el-checkbox__inner {
    border-color: #333;
}

.content>>>.el-checkbox {
    font-size: 16px;
}


.content>>>.el-tag {
    line-height: 26px;
    font-size: 18px;
    font-weight: 500;
}

.content>>>.el-step__head.is-success {
    color: green;
    border-color: green;
}

.blank {
    width: 160px;
    height: 35px;
    border-color: #e6a13c;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    box-sizing: border-box;
    margin: auto 5px;
}

.blank>>>.el-tag {
    text-align: center;
    flex: 1;
    margin: 0;
}

.blank.right>>>.el-tag--dark {
    color: green;
    border-color: green;
    background-color: rgb(240, 240, 240);
}

.blank.wrong>>>.el-tag--dark {
    background-color: rgb(240, 240, 240);
    border-color: red;
    color: red;
}

.v-line {
    height: 20px;
    width: 2px;
    background: #ccc;
}
</style>
