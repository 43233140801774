<template>
    <el-card class="margin-t no-shadow no-border">
        <div :class="{ 'mock-card-body': isMock }">
            <div v-for="(row, index0) in source.words_en.replaceAll('^', '').split('\n')" :key="index0">
                <div v-if="row" class="mt tj flex flex-wrap" :class="{ 'mt5': index0 > 0 }">

                    <h4 v-word v-for="(word, index) in row.split(' ')" :key="index"
                        :class="isMock ? 'word-mock' : 'word'">{{
                            word
                        }}</h4>
                </div>
                <br v-else />
            </div>

            <div class="margin-ts" v-if="source && source.tag == 'WE'">
                {{ source.words_zh }}
            </div>

            <div class="margin-t">
                <el-input :disabled="submitted == 1" v-model="content" type="textarea"
                    :autosize="{ minRows: 5, maxRows: 30 }"></el-input>
            </div>
            <div class="st1 margin-t bold" :class="wordClass()">Total word count：{{ wordCount() }}</div>
        </div>
    </el-card>
</template>

<script>
import bus from "../common/bus";

export default {
    name: "Writing",
    data() {
        return {
            time: 0,
            time_percentage: 0,
            status: 0,
            content: "",
            in_time: 0,//准备时间
            on_time: 0,//答题时间,
            submitted: 0
        };
    },

    watch: {

        content: (val) => {
            bus.$emit("content", val);
        },

        source: function () {
            let info = this.$store.getters.getType(this.tag);
            this.in_time = info.in_time;
            this.on_time = info.on_time;
            this.content = "";
            this.timer(this.on_time);
        },
    },

    props: {
        source: {
            type: Object,
            default: () => {
                return {};
            },
        },
        tag: {
            type: String,
            default: "SWT",
        },
        isMock: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.$bus.$on("submit", (flag) => {
            this.submitted = flag;
        })
    },
    methods: {
        getContent() {
            return this.content;
        },
        wordClass() {
            if (!this.isMock) {
                if (this.source.tag == "SST" && (this.wordCount() < 50 || this.wordCount() > 70)) {
                    return "red";
                } else if (this.source.tag == "SWT" && (this.wordCount() < 5 || this.wordCount() > 75)) {
                    return "red";
                } else if (this.source.tag == "WE" && (this.wordCount() < 200 || this.wordCount() > 300)) {
                    return "red";
                }
            }

            return "";
        },
        wordCount() {
            if (this.content) {
                let content = this.content.trim().replace(/[^-\w]+/g, ' ');
                if (content.trim()) {
                    return content.trim().split(" ").length;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        },

        reDo() {
            this.submitted = 0;
            this.content = "";
        },


        format_time(time) {
            let minute = parseInt(time / 60);
            let second = time - minute * 60;
            return (minute < 10 ? `0${minute}` : minute) + ":" + (second < 10 ? `0${second}` : second);
        },

        timer(time) {
            if (time != undefined) this.time = time;
            let that = this;
            setTimeout(() => {
                if (this.status == 0) {
                    if (that.time > 0) {
                        that.time--;
                        that.timer();
                        that.time_percentage = parseInt((that.on_time - that.time) / that.on_time * 100);
                    } else {
                        this.status = 1;
                    }
                }
            }, 1000);
        },
    },
};
</script>
<style scoped>
.st1.red {
    color: red;
}

.mt5 {
    margin-top: 5px;
}
</style>
