<template>
    <div class="padding"
        style="background-color: #fff; border:1px solid #BAC3D5; width: 360px;position: relative;text-align: left;">
        <h4 class="center">Recorded Answer</h4>
        <div class="margin-t">
            <div class="st1" style="font-weight:600;">Current Status:</div>
            <div class="margin-t st1" v-html="get_record_status()"></div>
        </div>

        <div class="flex flex-v-center margin-t">
            <el-progress :stroke-width="10" :show-text="false" class="flex1" :percentage="percentage"></el-progress>
            <div class="st1 margin-l">{{ format_time(time) }}</div>
        </div>

        <div v-if="showBtns" class="flex flex-h-center margin-t" style="height:32px;">
            <el-button :disabled="recording" type="primary" @click="record()" size="mini" class="flex1">Record</el-button>
            <el-button :disabled="!src || recording || playing" type="primary" @click="playAudio()" class="margin-x flex1"
                size="mini">Playback</el-button>
            <el-button :disabled="!playing && !recording" type="primary" @click="stop()" class="flex1"
                size="mini">Stop</el-button>
        </div>

        <audio ref="audio" style="display:none" src="/web/audio/du.mp3?v=1">
            浏览器太老咯，请升级浏览器吧~
        </audio>

        <audio ref="audio1" @timeupdate="onTimeupdate" @ended="onEnd" @loadedmetadata="onLoadedmetadata" @play="onPlay"
            @pause="onPause" v-if="src" style="display:none" :src="src" controls=""></audio>
    </div>
</template>

<script>
import Recorder from 'js-audio-recorder';
export default {
    name: "MockRecorder",
    data() {
        return {
            percentage: 0,
            recording: false,
            src: null,
            recorder: null,
            playing: false,
            duration: 0,
            timeJob: null,
            time: 0,

            timeJob0: null,
            time0: 0,
        };
    },

    props: {
        timeLimit: {
            type: Number,
            default: 10,
        },
        inTime: {
            type: Number,
            default: 10,
        },
        tag: {
            type: String,
            default: "",
        },

        showBtns: {
            type: Boolean,
            default: false,
        },

        beep: {
            type: Boolean,
            default: false,
        }
    },


    created() {
        this.recorder = new Recorder({
            sampleBits: 16,                 // 采样位数，支持 8 或 16，默认是16
            sampleRate: 16000,              // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
            numChannels: 1,                 // 声道，支持 1 或 2， 默认是1
            // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
        });
    },
    destroyed() {
        if (this.timeJob) {
            clearInterval(this.timeJob);
            this.timeJob = null;
        }

        if (this.timeJob0) {
            clearInterval(this.timeJob0);
            this.timeJob0 = null;
        }
    },
    methods: {
        refrsh(flag) {
            if (this.recording) {
                this.recorder.stop();
                this.recording = false;
            }


            if (this.playing) {
                this.$refs.audio1.pause();
                this.playing = false;
            }

            if (this.timeJob0) {
                clearInterval(this.timeJob0);
                this.time0 = null;
            }

            if (this.timeJob) {
                clearInterval(this.timeJob);
                this.timeJob = null;
            }
           
            this.time0 = 0;
            this.percentage = 0;
            this.time = 0;
            this.src = null;

            if (flag === 0) {
                this.start();
            }
        },
        format_time(time) {
            let minute = parseInt(time / 60);
            let second = time - minute * 60;
            return (minute < 10 ? `0${minute}` : minute) + ":" + (second < 10 ? `0${second}` : second);
        },

        get_record_status() {
            let txt = "&nbsp;";
            if (this.recording) {
                txt = "Recording";
            } else if (this.playing) {
                txt = "Playing"
            } else if (this.src != null) {
                txt = "Complete";
            } else if (this.time0 > 0) {
                txt = "Recording in " + this.time0 + " seconds";
            } else if (this.showBtns) {
                txt = "Click Record to Begin";
            }
            return txt;
        },
        onTimeupdate() {
            if (this.$refs.audio1.duration > 0) {
                this.percentage = parseInt(this.$refs.audio1.currentTime / this.$refs.audio1.duration * 100);
            }

        },
        onLoadedmetadata() {
            this.duration = Math.ceil(this.$refs.audio1.duration);
        },
        onPlay() {
            this.playing = true;
        },
        onPause() {
            this.playing = false;
        },
        onEnd() {
            this.percentage = 0;
            this.playing = false;
        },
        playAudio() {
            if (this.playing) {
                this.$refs.audio1.pause();
            } else {
                this.$refs.audio1.play();
            }
        },

        start() {
            this.src = null;
            this.time0 = this.inTime;
            if (this.timeJob0) {
                clearInterval(this.timeJob0);
                this.timeJob0 = null;
            }
            this.timeJob0 = setInterval(() => {
                this.time0--;
                console.log(this.time0)
                if (this.time0 == 0) {
                    clearInterval(this.timeJob0);
                    this.timeJob0 = null;
                    this.record();
                }
            }, 1000);
        },

        record() {
            this.src = null;
            this.time = 0;
            this.percentage = 0;

            this.recorder.start().then(() => {
                this.recording = true;
                this.timer();
                this.$emit("start");
            })

            if (this.beep || this.showBtns || this.tag == "RA" || this.tag == "DI" || this.tag == "RL") {
                if (this.$refs.audio) {
                    this.$refs.audio.play();
                }
            }
        },

        getContent() {
            return (this.src || this.recording) ? this.recorder.getWAVBlob() : null;
        },

        stop() {
            if (this.recording) {
                this.recorder.stop();
                this.recording = false;
                this.src = URL.createObjectURL(this.recorder.getWAVBlob());
                this.$emit("ended", this.src);
                this.$bus.$emit("content", this.recorder.getWAVBlob());
                if (this.showBtns) {
                    this.$nextTick(() => {
                        this.$refs.audio1.load();
                    })
                }
            }

            if (this.playing) {
                this.$refs.audio1.pause();
            }
        },

        timer() {
            if (this.timeJob) {
                clearInterval(this.timeJob)
            }
            this.timeJob = setInterval(() => {
                this.time++;
                this.percentage = parseInt(this.time / this.timeLimit * 100);
                if (this.time >= this.timeLimit || !this.recording) {
                    clearInterval(this.timeJob);
                    this.timerJob = null;
                    if (this.showBtns) {
                        this.percentage = 0;
                    }
                    this.stop();
                }
            }, 1000);
        },

    },
};
</script>
<style scoped>
.time>>>.el-progress-bar__outer {
    background-color: #ddd !important;
}

.Speaking>>>.el-button--primary {
    background-color: #de7d89;
    border-color: #de7d89;
}

.Speaking>>>.el-slider__button {
    border-color: #de7d89;
}
</style>
