<template>
    <el-card class="margin-t no-shadow no-border listening">
        <div :class="{ 'mock-card-body': isMock }">
            <div>
                <div v-if="isMock" class="flex flex-h-center">
                    <mock-player ref="player" @play="playStart" @ended="playerEnd" :no="source.no" sort="Listening" />
                </div>
                <audio-player v-else ref="player" @play="playStart" @ended="playerEnd" :no="source.no"
                    sort="Listening" />
            </div>


            <el-image :src="$host + source.images" v-if="source.tag == 'DI'"></el-image>


            <div v-if="source.tag == 'MCS-L' || source.tag == 'MCM-L'" class="margin-t">
                <div  class="lt margin-y"> <span v-word v-for="(word, index) in source.question.replace(/\s+/g, ' ').split(' ')"
                        :key="index" class="word">{{ word }}</span></div>
            </div>


            <div :class="{ 'mock-checkbox': isMock }"
                v-if="source.tag == 'HCS' || source.tag == 'MCS-L' || source.tag == 'SMW'">
                <div v-for="(words, index) in options" :key="index">
                    <el-radio :disabled="submitted == 1" class="margin-t" :label="choice[index]" v-model="content[0]">
                        <span
                            :class="{ 'right': submitted == 1 && (content.indexOf(choice[index]) != -1 || answers.indexOf(choice[index]) != -1), 'wrong': submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) == -1, 'choose': submitted == 1 && content.indexOf(choice[index]) != -1, 'word': !isMock, 'word-mock': isMock }"
                            v-for="(word, index1) in words" :key="index1">{{ word }}</span>
                        <!-- <i v-if="(submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) != -1) || (view_flag && answers.indexOf(choice[index]) != -1)"
                        style="color:green;font-weight:bold;" class="check-icon el-icon-check"></i>

                    <i v-if="submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) == -1"
                        style="color:red;font-weight:bold;" class="check-icon el-icon-close"></i> -->
                    </el-radio>
                </div>
            </div>

            <el-checkbox-group :class="{ 'mock-checkbox': isMock }" :disabled="submitted == 1"
                v-if="source.tag == 'MCM-L'" v-model="content">
                <div v-for="(words, index) in options" :key="index">
                    <el-checkbox class="margin-t" :label="choice[index]">
                        <span v-for="(word, index1) in words" :key="index1"
                            :class="{ 'right': submitted == 1 && (content.indexOf(choice[index]) != -1 || answers.indexOf(choice[index]) != -1), 'wrong': submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) == -1, 'choose': submitted == 1 && content.indexOf(choice[index]) != -1, 'word': !isMock, 'word-mock': isMock }">{{
                                word
                            }}</span>

                        <!-- <i v-if="(submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) != -1) || (view_flag && answers.indexOf(choice[index]) != -1)"
                        style="color:green;font-weight:bold;" class="el-icon-check"></i>

                    <i v-if="submitted == 1 && content.indexOf(choice[index]) != -1 && answers.indexOf(choice[index]) == -1"
                        style="color:green;font-weight:bold;" class="el-icon-close"></i> -->
                    </el-checkbox>
                </div>
            </el-checkbox-group>


            <div class="margin-t" v-else-if="source.tag == 'SST' || source.tag == 'WFD'">
                <el-input :disabled="submitted == 1" v-model="content[0]" ref="textarea" type="textarea"
                    :autosize="{ minRows: source.tag == 'SST' ? 5 : 2, maxRows: 30 }"></el-input>
                <div class="st1 margin-t bold" :class="wordClass()">Total word count：{{ wordCount() }}</div>
                <div class="flex margin-t" v-if="source.tag == 'WFD' && !isMock">
                    <el-switch class="flex0" v-model="view_flag" :active-text="$t('答案')" active-color="#E6A23C"
                        inactive-text=""></el-switch>
                    <div v-if="view_flag" style="position: relative;bottom:3px;">
                        <div class="margin-x flex flex-wrap">
                            <span :class="isMock ? 'word-mock' : 'word'"
                                v-for="(word, index) in source.answer.replaceAll('^', '').split(' ')" v-word
                                :word="word.split('/')[0]" :key="index">{{
                                    word
                                }}</span>
                        </div>
                        <div class="margin-x">
                            {{ source.words_zh }}
                        </div>

                        <div v-if="source.sense_group">
                            <div class="margin-x margin-t">
                                {{ $t('意群分割版') }}:
                            </div>
                            <div class="margin-x margin-ts">
                                <span :class="isMock ? 'word-mock' : 'word'"
                                    v-for="(word, index) in source.sense_group.replaceAll('^', '').split(' ')" v-word
                                    :key="index">{{
                                        word
                                    }}</span>
                            </div>
                        </div>

                        <div v-if="source.analysis">
                            <div class="margin-x margin-t">
                                {{ $store.getters.typeSettings(source.tag)[0] + ":" }}
                            </div>
                            <div class="margin-x margin-ts">
                                <div v-for="(row, index) in source.analysis.split('\n')" :key="index">
                                    <span :class="isMock ? 'word-mock' : 'word'"
                                        v-for="(word, index1) in row.replaceAll('^', '').split(' ')" v-word
                                        :key="index1">{{
                                            word
                                        }}</span>
                                </div>

                            </div>
                        </div>

                        <div v-if="source.gmat_text" class="margin-x st margin-t border padding br">
                            <span>{{ $t('机经点评') }}：</span>
                            {{ source.gmat_text }}
                        </div>

                    </div>
                </div>
            </div>

            <div v-else-if="source.tag == 'FIB-L'" class="mt tj flex flex-v-center flex-wrap margin-t">
                <h4 v-for="(word, index) in words" :key="index" :class="isMock ? 'word-mock' : 'word'">
                    <div v-if="word.input" class="flex flex-v-center select"
                        :class="{ 'right': submitted == 1 && answers[word.key].split('/').indexOf(content[word.key].trim()) != -1, 'wrong': submitted == 1 && content[word.key].trim() != answers[word.key] }">
                        <el-input class="input-primary" :disabled="submitted == 1"
                            v-model="content[word.key]"></el-input>
                        <i v-if="submitted == 1 && answers[word.key].split('/').indexOf(content[word.key].trim()) != -1"
                            style="color:green;font-weight:bold;margin-top:5px" class="el-icon-check"></i>
                        <div v-word v-if="submitted == 1 && answers[word.key].split('/').indexOf(content[word.key].trim()) == -1"
                            style="color:red;font-weight:bold;margin-top:5px;white-space: nowrap;">({{ answers[word.key]
                            }})
                        </div>
                    </div>
                    <span v-else>
                        <span v-word>{{ word }}</span>
                    </span>
                </h4>
            </div>


            <div v-else-if="source.tag == 'HIW'" class="mt tj flex flex-v-center flex-wrap margin-t">
                <h4 v-for="(word, index) in words" :key="index">

                    <span v-if="word.replace(/\W/g, '').length == 0" style="margin-left:-1px;margin-right: 2.5px;">{{
                        word
                        }}</span>

                    <span v-else @click="selectWord(index, word)" class="word"
                        :class="{ 'selected': wordIndexs.indexOf(index) != -1, 'right': submitted == 1 && word.indexOf('/') != -1 && wordIndexs.indexOf(index) != -1, 'wrong': submitted == 1 && ((word.indexOf('/') != -1 && wordIndexs.indexOf(index) == -1) || (word.indexOf('/') == -1 && wordIndexs.indexOf(index) != -1)) }">{{
                            word.split('/')[0]
                        }}

                        <i v-if="submitted == 1 && word.indexOf('/') != -1 && wordIndexs.indexOf(index) != -1"
                            style="color:green;font-weight:bold;" class="el-icon-check"></i>
                        <i v-if="submitted == 1 && word.indexOf('/') == -1 && wordIndexs.indexOf(index) != -1"
                            style="color:red;font-weight:bold;" class="el-icon-close"></i>

                    </span>
                    <span class="word m0"
                        :class="{ 'right': submitted == 1 && wordIndexs.indexOf(index) != -1, 'wrong': submitted == 1 && wordIndexs.indexOf(index) == -1 }"
                        v-if="word.indexOf('/') != -1 && submitted">({{ word.split('/')[1].replace(/\W/g, '') }})</span>

                    <span v-if="submitted && word.indexOf('/') != -1 && /^\w+\W$/.test(word.split('/')[1])">{{
                        word.split('/')[1].substr(-1)
                        }}</span>
                </h4>
            </div>
        </div>
        <audio v-if="source.tag == 'SMW'" ref="audio" style="display:none" src="/web/audio/beep.mp3">
            浏览器太老咯，请升级浏览器吧~
        </audio>
    </el-card>
</template>

<script>
import AudioPlayer from './AudioPlayer.vue'
import MockPlayer from './MockPlayer.vue'
export default {
    components: {
        AudioPlayer, MockPlayer
    },
    name: "Listening",
    data() {
        return {
            time: 0,
            audio_list: [],
            voice: "Andy",
            status: 0,
            content: [""],
            options: [],
            words: [],
            in_time: 0,
            on_time: 0,
            choice: ["A", "B", "C", "D", "E", "F", "G"],
            wordIndexs: [],
            submitted: 0,
            view_flag: false,
            answers: [],
        };
    },

    watch: {
        source: function () {
            this.reDo();
        },
        content: function (val) {
            let flag = true;

            let val1 = val;
            if (this.source.tag == "SST" || this.source.tag == "HIW") {
                val.forEach((item) => {
                    console.log(item)
                    if (!item) {
                        flag = false;
                    }
                })
            } else if (this.source.tag == "FIB-L") {
                val1 = [];
                val.forEach((item) => {
                    val1.push(item.trim())
                })
            }
            if (flag) {
                this.$bus.$emit("content", val1);
            } else {
                this.$bus.$emit("content", null);
            }
        },
    },

    props: {
        source: {
            type: Object,
            default: () => {
                return {};
            },
        },
        isMock: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.$bus.$on("submit", (flag) => {
            this.submitted = flag;
        })
        this.$bus.$on("view_answer", flag => {
            if (flag) {
                this.view_flag = true;
            } else {
                this.view_flag = false;
            }
        })
        this.reDo();
    },
    methods: {
        getContent() {
            return this.$refs.player.isPlaying() ? null : this.content;
        },
        wordClass() {
            if (!this.isMock) {
                if (this.source.tag == "SST" && (this.wordCount() < 50 || this.wordCount() > 70)) {
                    return "red";
                } else if (this.source.tag == "SWT" && (this.wordCount() < 5 || this.wordCount() > 75)) {
                    return "red";
                } else if (this.source.tag == "WE" && (this.wordCount() < 200 || this.wordCount() > 300)) {
                    return "red";
                }
            }

            return "";
        },
        wordCount() {
            if (this.content[0]) {
                let content = this.content[0].trim().replace(/[^-\w]+/g, ' ');
                if (content.trim()) {
                    return content.trim().split(" ").length;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        },
        selectWord(index, word) {
            if (this.submitted == 1) return;
            if (this.wordIndexs.indexOf(index) != -1) {
                this.wordIndexs.splice(this.wordIndexs.indexOf(index), 1)
                this.content.splice(this.content.indexOf(word), 1)
            } else {
                this.wordIndexs.push(index);
                this.content.push(word);
            }
            console.log(this.content)
        },

        reDo() {
            let source = this.source;
            this.submitted = 0;
            if (source.tag == "SST" || source.tag == "WFD") {
                this.content = [""];
            } else {
                this.content = [];
            }
            if (source.tag == "MCM-L" || source.tag == "FIB-L" || source.tag == "MCS-L" || source.tag == 'SMW' || source.tag == 'HCS' || source.tag == 'HIW') {
                this.answers = source.answer.split(",");
            }
            this.wordIndexs = [];
            this.options = [];
            let info = this.$store.getters.getType(source.tag);
            this.in_time = info.in_time;
            this.on_time = info.on_time;

            this.audio_list = [this.$host + "/api/audio/" + source.no + "/" + this.voice];
            let question = source.question ? source.question : source.words_en;
            if (question) {
                this.words = question.replace(/\s+/g, ' ').replace(/\s+\//g, '/').split(' ');

                if (source.tag == "HIW") {
                    let words = [];
                    this.words.forEach((word) => {
                        if (word.indexOf("-") != -1) {
                            let items = word.split("-");
                            items.forEach((item, index) => {
                                words.push(item);
                                if (index < items.length - 1) {
                                    words.push("-");
                                }
                            })
                        } else {
                            let str = word.replace(/\W+$/, (val) => {
                                return " " + val;
                            })
                            str = str.replace(/^\W+/, (val) => {
                                return val + " ";
                            })
                            let items = str.split(" ");
                            items.forEach((item) => {
                                words.push(item);
                            })

                        }
                    });
                    this.words = words;
                }

                let text = null;
                let that = this;
                let key = 0;
                this.words.forEach((word, index) => {
                    if (word == "__") {
                        that.words[index] = { key: key++, input: 1 };
                        this.content.push("");
                    } else if (word.indexOf("(") == 0) {
                        if (source.tag == 'FIB-RW') {
                            text = word.replace("(", "").replace(")", "");
                            that.words[index] = { key: key++, options: text.split("/") };

                        } else if (source.tag == 'FIB-R') {
                            that.words[index] = { draggable: true };
                        }
                    }
                });
            }


            if (source.options) {
                let that = this;
                let rows = source.options.replace(/\n+/g, '\n').split('\n');
                rows.forEach((row) => {
                    that.options.push(row.replace(/\s+/g, ' ').split(" "));
                })
            }

            // if (this.$refs.player) {

            // }


            this.$nextTick(() => {
                if (source.tag == "SST" || source.tag == "WFD") {
                    this.$refs.textarea.resizeTextarea();
                }
                this.$refs.player.stop();
                this.$refs.player.play(this.in_time);
            })

            // setTimeout(() => {
            //     this.$refs.player.play(this.in_time);
            // }, 1000);
        },
        playStart() {
            this.status = 1;
        },
        playerEnd() {
            if (this.source.tag == 'SMW') {
                this.$refs.audio.play();
            }
        },

        format_time(time) {
            let minute = parseInt(time / 60);
            let second = time - minute * 60;
            return (minute < 10 ? `0${minute}` : minute) + ":" + (second < 10 ? `0${second}` : second);
        },

        // timer(time) {
        //     if (time != undefined) this.time = time;
        //     let that = this;
        //     setTimeout(() => {
        //         if (that.time > 0) {
        //             that.time--;
        //             that.timer();
        //             that.time_percentage = parseInt((this.on_time - this.time) / this.on_time * 100);
        //         }
        //     }, 1000);
        // },

        changeVoice(voice) {
            this.audio_list = [this.$host + "/api/audio/" + this.source.no + "/" + voice];
        },
    },
};
</script>
<style scoped>
.st1.red {
    color: red;
}

.word.right,
.right {
    color: green;
}

.word.wrong,
.wrong {
    color: red;
}

.word.m0 {
    padding: 0;
    margin: 0;
}

.input-primary>>>.el-input__inner {
    font-size: 16px;
    color: #E6A23C;
    font-weight: bold;
}

.select>>>.el-input__inner {
    height: 30px;
    width: 160px;
    margin-top: 5px;
}


.select.wrong>>>.el-input__inner {
    border-color: red !important;
    font-weight: bold;
    font-size: 16px;
    color: red;
}

.select.right>>>.el-input__inner {
    border-color: green !important;
    font-weight: bold;
    font-size: 16px;
    color: green;
}

.word:hover {
    /* background-color: #f0f0f0; */
    border-radius: 5px;
}

.selected {
    border-radius: 5px;
    background-color: #E6A23C;
    color: #fff;
}

.listening>>>.el-radio,
.listening>>>.el-checkbox {
    display: flex;
    align-items: center;
}

.listening>>>.el-radio__inner {
    border-color: #333333 !important;
}

.listening>>>.el-checkbox__inner {
    border-color: #333333 !important;
}


.listening>>>.el-radio__label,
.listening>>>.el-checkbox__label {
    display: flex;
    flex-wrap: wrap;
}
</style>
