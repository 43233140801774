<template>
    <transition name="el-zoom-in-bottom">
        <div v-show="show"
            style="position:fixed;left:50%;margin-left:-600px;width:1200px;bottom: 0;background-color: #fff; box-shadow: 0 -5px 5px #ccc;  z-index: 1000;">
            <div class="padding margin-x">
                <h3 class="">{{ $t('答案解析') }}</h3>
                <div class="mt margin-t" v-if="content">
                    <div class="text" :class="{ 'b': index == 0 }"
                        v-for="(row, index) in content.split('\n')" :key="index">
                        <template v-for="(part, index1) in row.split('^')">
                            <span :key="index1" :class="{ 'bold-line': index1 % 2 == 1 }">{{ part }}</span>
                        </template>
                        <div :key="index" v-if="!row" style="height: 10px;"></div>
                    </div>
                </div>

                <div class="flex margin-t flex-between">
                    <el-button round @click="show = false" class="btn">
                        {{ $t('关闭') }}
                    </el-button>
                    <div class="flex flex-v-center" v-loading="loading">
                        <div class="center">
                            <el-button round class="btn">
                                <div class="flex flex-v-center">
                                    <div v-if="flag == 1" style="margin-right: 5px;">
                                        <el-image @click="record(-1)" src="/web/image/zan_checked.png"
                                            style="width:24px;height:24px;line-height: 24px;"
                                            class="pointer"></el-image>
                                        <span style="font-size: 14px;color:#e6a13c;position: relative;bottom: 10px;">{{
                                            count }}</span>
                                    </div>

                                    <el-image v-else @click="record(1)" src="/web/image/zan.png"
                                        style="width:24px;height:24px; margin-right: 5px;line-height: 24px;"
                                        class="pointer"></el-image>
                                    <span>{{ $t('有帮助') }}</span>

                                </div>
                            </el-button>
                        </div>

                        <div class="margin-l center">
                            <el-button round class="btn">
                                <div class="flex flex-v-center">
                                    <el-image v-if="flag == 0" @click="record(-1)" src="/web/image/zan_checked.png"
                                        style="width:24px;height:24px; margin-right: 5px;line-height: 24px;transform: rotateX(180deg);"
                                        class="pointer"></el-image>


                                    <el-image v-else @click="record(0)" src="/web/image/zan.png"
                                        style="width:24px;height:24px; margin-right: 5px;line-height: 24px;transform: rotateX(180deg);"
                                        class="pointer"></el-image>
                                    <span>{{ $t('没帮助') }}</span>
                                </div>
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>

            <div style="height:30px"></div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "analysisPopup",
    data() {
        return {
            show: false,
            index: 0,
            source: null,
            content: null,
            count: 0,
            flag: 0,
            loading: false
        };
    },
    methods: {
        popup(index, souce) {
            if (this.show && this.index == index) return;
            this.show = true;
            this.index = index;
            this.source = souce;
            this.content = this.source.analysis.split(/\n\d\./g)[this.index].replace(/\d\./, '')
            this.record();
        },


        close() {
            this.show = false;
            this.content = "";
        },

        record(flag) {
            this.loading = true;
            let data = {
                source_key: this.source.no + "_" + this.index
            }
            if (flag != undefined) {
                data["flag"] = flag;
            }
            this.$http.post("/api/source/analysis/record", data).then((res) => {
                this.count = res.count;
                this.flag = res.flag;
                this.loading = false;
            })
        },
    },
};
</script>
<style scoped>
.btn {
    padding-top: 8px;
    padding-bottom: 8px;
}

.text{
    font-size: 14px;
}

.text.b {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
}
</style>