<template>
    <div class="padding" style="background-color: #fff; border:1px solid #BAC3D5; width: 360px;position: relative;">

        <div class="flex flex-v-center">
            <strong>Status:</strong>
            <div class="margin-x" v-html="get_player_status()"></div>
        </div>


        <div class="flex flex-v-center margin-lt">
            <div>Volume:</div>
            <div class="flex1 margin-l">
                <el-slider :step="0.1" @change="updateVolume" :min="0" v-model="volume" :max="1"></el-slider>
            </div>

        </div>

        <div class="flex flex-v-center margin-t">
            <div class="flex1">
                <el-progress :percentage="timePercentage" :stroke-width="10" :show-text="false"></el-progress>
            </div>
        </div>


        <div v-if="showBtns" class="flex flex-v-center flex-between  margin-t">
            <el-button @click="play" size="small">PLAY</el-button>
            <el-button @click="stop" size="small">STOP</el-button>
        </div>


        <audio ref="audio" preload="metadata" style="display:none" :src="src" @play="onPlay" @pause="onPause"
            @ended="onEnded" @timeupdate="onTimeUpdate" @loadedmetadata="onLoadedmetadata">
            浏览器太老咯，请升级浏览器吧~
        </audio>

    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "MockPlayer",

    data() {
        return {
            playing: false,
            duration: 0,
            volume: 0.5,
            timePercentage: 0,
            timeJob: null,
            time: 0,
        };
    },


    watch: {
        no(val) {
            if (val) {
                this.updateAudioList();
            }
        }
    },

    props: {
        no: {
            type: String,
            default: "",
        },
        src: {
            type: String,
            default: "",
        },

        inTime: {
            type: Number,
            default: 0,
        },

        showBtns: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        if (this.no) {
            this.$nextTick(() => {
                this.updateAudioList();
            })
        }
    },

    destroyed() {
        if (this.timeJob) {
            clearInterval(this.timeJob);
            this.timeJob = null;
        }
    },


    methods: {
        isPlaying() {
            return this.time > 0 || this.playing;
        },
        get_player_status() {
            let txt = "Complete";
            if (this.playing) {
                txt = "Playing";
            } else if (this.time > 0) {
                txt = "Beginning in " + this.time + " seconds";
            } else if (this.showBtns) {
                txt = "Click Play to Begin";
            }
            return txt;
        },

        stop() {
            this.$refs.audio.pause();
        },


        play(time) {
            if (time > 0) {
                this.start(time);
            } else {
                this.$nextTick(() => {
                    if (this.$refs.audio) {
                        this.$refs.audio.play();
                        this.$emit("play");
                    }
                })
            }
        },

        onTimeUpdate() {
            if (this.duration > 0) {
                this.timePercentage = parseInt(this.$refs.audio.currentTime / this.duration * 100);
            }

        },

        onLoadedmetadata() {
            if (this.duration > 0) return;
            this.$nextTick(() => {
                if (this.$refs.audio.duration == window.Infinity && this.duration == 0) {
                    this.$http.post("/api/audio/duration/" + this.no + "/Origin").then((duration) => {
                        if (duration == 0) {
                            setTimeout(() => {
                                this.onLoadedmetadata();
                            }, 1000);
                        } else {
                            this.duration = Math.ceil(duration);
                        }
                    })
                } else {
                    this.duration = Math.ceil(this.$refs.audio.duration);
                }
            })

        },


        updateVolume() {
            this.$refs.audio.volume = this.volume
        },


        updateAudioList() {
            this.$refs.audio.currentTime = 0;
            this.$refs.audio.pause();
            this.duration = 0;
            this.playing = false;
            let src = this.$host + "/api/audio/" + this.no + "/Origin?v=" + moment().format('MMDDHH');
            this.$refs.audio.src = src;
        },
        start(time) {
            this.time = time || 0;
            if (this.time == 0) {
                this.play();
            } else {
                this.timePercentage = 0;
                this.timer();
            }
        },

        timer() {
            if (this.timeJob) {
                clearInterval(this.timeJob)
            }
            this.timeJob = setInterval(() => {
                this.time--;
                if (this.time == 0) {
                    clearInterval(this.timeJob);
                    this.timerJob = null;
                    this.play();
                }
            }, 1000);
        },


        onPlay() {
            this.playing = true;
        },
        onPause() {
            this.playing = false;
        },
        onEnded() {
            this.playing = false;
            this.timePercentage = 100;
            this.$emit("ended");
        },
    },
};
</script>
<style scoped></style>
