<template>
    <el-card class="margin-t no-shadow no-border">
        <div :class="{ 'mock-card-body': isMock }">

            <div style="height:160px;" v-if="tag == 'RA'">
                <div v-if="ra_mode" class="mt tj flex flex-wrap">
                    <h4 class="pointer word-mock" v-for="(word, index) in source.words_en.split(' ')"
                        @click="select(index)" :key="index"
                        :class="{ 'choose1': index >= ra_startIndex && index <= ra_endIndex, 'choose2': (index == ra_startIndex || index == ra_endIndex) }">
                        {{ word }}</h4>
                </div>

                <div v-else class="mt tj flex flex-wrap">
                    <h4 v-for="(word, index) in source.words_en.split(' ')" v-word :key="index"
                        :class="isMock ? 'word-mock' : 'word pointer'">{{ word }}</h4>
                </div>
            </div>

            <div v-if="tag == 'RA'" class="center margin-lt">

                <AudioRecorder :ra_flag="ra_mode && ra_status == 0"
                    v-show="(ra_status == 1 && ra_mode) || (!ra_mode && !isMock)" ref="recorder" :tag="source.tag"
                    :in_time="in_time" @timeUpdated="timeUpdated" :time_limit="on_time" @start="recorderStart"
                    @ended="recorderEnd">
                </AudioRecorder>

                <div v-if="isMock">
                    <div class="flex flex-h-center">
                        <MockRecorder ref="recorder" :tag="source.tag" :inTime="in_time" :time-limit="on_time"
                            @start="recorderStart" @ended="recorderEnd">
                        </MockRecorder>
                    </div>
                    <div class="margin-lt">
                        <el-switch active-text="一句话模式" v-model="ra_mode" inactive-text="全读模式"></el-switch>
                    </div>
                </div>

                <div v-if="!isMock && ra_mode && ra_status == 0" class="flex flex-h-center">
                    <div class="center padding flex flex-column flex-h-center"
                        style="background-color: #f0f0f0; border-radius: 20px;width: 360px; height: 136px;">

                        <div>
                            <div class="center color-primary bold">词数：{{ ra_startIndex != -1 ? ra_endIndex - ra_startIndex
                                + 1 :0 }}</div>
                            <div class="st center margin-ts">点击所选句子的首词和尾词来确定要读的部分<br>（建议词数：8-12个单词）</div>
                            <el-button type="primary"
                                :disabled="ra_startIndex == -1 || ra_endIndex == -1 || ra_startIndex == ra_endIndex"
                                @click="raStart()" class="margin-t" size="mini">确定选句</el-button>
                        </div>
                        <div class="margin-t center st1" v-if="recorder_time > 0">Recording in {{ recorder_time }}</div>
                    </div>
                </div>
            </div>

            <div v-if="source && (tag == 'RS' || tag == 'RL' || tag == 'ASQ')">
                <div class="flex flex-h-center" v-if="isMock">
                    <mock-player ref="player" @play="playStart" @ended="playerEnd" :no="source.no" />
                </div>

                <audio-player v-else ref="player" @play="playStart" @ended="playerEnd" sort="Speaking"
                    :no="source.no" />
            </div>

            <div v-if="tag == 'DI'" class="flex flex-v-center">
                <div class="flex1 flex flex-h-center">
                    <el-image @load="loadImg" fit="contain" style="width: 400px;" ref="image" @click="preview"
                        :preview-src-list="[imageUrl()]" :src="imageUrl()"></el-image>
                </div>
                <div class="flex1 flex flex-h-center">
                    <div class="flex flex-h-center" v-if="isMock">
                        <MockRecorder ref="recorder" :source="source" :tag="source.tag" :inTime="in_time"
                            :time-limit="on_time" @start="recorderStart" @ended="recorderEnd">
                        </MockRecorder>
                    </div>

                    <AudioRecorder v-else ref="recorder" :tag="source.tag" :in_time="in_time" :time_limit="on_time"
                        @start="recorderStart" @ended="recorderEnd">
                    </AudioRecorder>
                </div>
            </div>

            <div v-if="tag != 'DI' && tag != 'RA'" class="center margin-lt">
                <div class="flex flex-h-center" v-if="isMock">
                    <MockRecorder ref="recorder" :tag="source.tag" :inTime="in_time" :time-limit="on_time"
                        @start="recorderStart" @ended="recorderEnd">
                    </MockRecorder>
                </div>

                <AudioRecorder v-else ref="recorder" :tag="source.tag" :in_time="in_time" :time_limit="on_time"
                    @start="recorderStart" @ended="recorderEnd">
                </AudioRecorder>
            </div>
        </div>
    </el-card>
</template>

<script>
import moment from 'moment';
import AudioRecorder from './AudioRecorder.vue'
import AudioPlayer from './AudioPlayer.vue'
import MockRecorder from './MockRecorder.vue'
import MockPlayer from './MockPlayer.vue'
export default {
    components: {
        AudioPlayer, AudioRecorder, MockRecorder, MockPlayer
    },
    name: "Speaking",
    data() {
        return {
            time: 0,
            voice: "Andy",
            status: 0,
            in_time: 0,//准备时间
            on_time: 0,//答题时间
            ra_mode: false,
            ra_startIndex: -1,
            ra_endIndex: -1,
            ra_status: 0,
            ra_flag: true,
            previewHeight: 0,
            previewWidth: 0,
            recorder_time: 0,
        };
    },

    watch: {
        source: function () {
            this.$nextTick(() => {
                this.reDo();
            })
        },
    },

    props: {
        source: {
            type: Object,
            default: () => {
                return {};
            },
        },
        tag: {
            type: String,
            default: "RA",
        },

        isMock: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.reDo();

        this.$bus.$on("ra_mode", (ra_mode) => {
            this.ra_startIndex = -1;
            this.ra_endIndex = -1;
            this.ra_status = 0;
            this.ra_mode = ra_mode;
            this.ra_flag = true;

            // if (!this.ra_mode) {
            //     this.$nextTick(() => {
            //         if (this.$refs.recorder) {
            //             this.$refs.recorder.refrsh(0);
            //         }
            //     })
            // }
        })
    },
    methods: {
        timeUpdated(val) {
            this.recorder_time = val
        },

        loadImg(e) {
            this.previewHeight = e.target.naturalHeight
            this.previewWidth = e.target.naturalWidth
        },

        raStart() {
            this.ra_status = 1;

            let words = this.source.words_en.split(' ');

            let contents = [];
            for (let i = this.ra_startIndex; i <= this.ra_endIndex; i++) {
                contents.push(words[i]);
            }
            this.$bus.$emit("ra_content", contents.join(' '));
            // this.$nextTick(() => {
            //     this.$refs.recorder.refrsh(0);
            // })
        },
        select(index) {
            if (this.ra_status == 1) return;
            if (this.ra_flag) {
                this.ra_startIndex = index;
                this.ra_endIndex = index;
            } else {
                if (index <= this.ra_startIndex) {
                    this.ra_startIndex = -1;
                    this.ra_endIndex = -1;
                } else {
                    this.ra_endIndex = index;
                }
            }
            this.ra_flag = !this.ra_flag;
        },

        imageUrl() {
            return this.$host + '/upload/图片/DI/' + this.source.no + '.png?v=' + (moment().format('MMDDHHmm'))
        },
        getRaContent() {
            if (this.ra_mode) {
                let words = this.source.words_en.split(' ');
                let contents = [];
                for (let i = this.ra_startIndex; i <= this.ra_endIndex; i++) {
                    contents.push(words[i]);
                }
                return contents.join(' ');
            }
            return null
        },
        getContent() {
            return this.$refs.recorder.getContent();
        },
        reDo(flag) {
            let source = this.source;
            if (source) {
                let info = this.$store.getters.getType(source.tag);
                if (source.tag == "RA") {
                    let wordCount = source.words_en.split(" ").length;
                    if (wordCount < 40) {
                        this.in_time = 30;
                    } else if (wordCount < 50) {
                        this.in_time = 35;
                    } else {
                        this.in_time = 40;
                    }
                    if (!flag) {
                        this.$bus.$emit("ra_mode", this.ra_mode);
                    }
                    this.ra_status = 0;
                } else {
                    this.in_time = info.in_time;
                }
                this.on_time = info.on_time;
                if (this.tag == 'RS' || this.tag == 'RL' || this.tag == 'ASQ') {
                    if (this.isMock) {
                        this.$nextTick(() => {
                            this.$refs.recorder.refrsh();
                            this.$refs.player.start(3);
                        })
                    } else {
                        this.$nextTick(() => {
                            this.$refs.player.play(3);
                            this.$refs.recorder.refrsh(-1);
                        })
                    }
                } else {
                    if (this.isMock) {
                        this.$nextTick(() => {
                            this.$refs.recorder.refrsh(0);
                        })
                    } else {
                        this.$nextTick(() => {
                            this.$refs.recorder.refrsh(0);
                        })
                    }
                }
            }
        },
        playStart() {
            this.status = 1;
            if (this.$refs.recorder) {
                this.$refs.recorder.refrsh(-1);
            }
        },
        playerEnd() {
            this.startRecorder();
        },
        recorderStart() {
            this.status = 2
            if (this.$refs.player) {
                this.$refs.player.stop();
            }

        },
        recorderEnd() {
            this.status = 3;
        },

        startRecorder() {

            if (this.$refs.player) {
                this.$refs.player.stop();
            }

            if (this.tag == 'RS' || this.tag == 'ASQ') {
                this.$nextTick(() => {
                    // this.$refs.player.play(3);
                    this.$refs.recorder.refrsh(-1);
                    this.$refs.recorder.start();
                })
            } else {
                this.$nextTick(() => {
                    this.$refs.recorder.refrsh(0);
                })
            }


            // if (this.tag == "RL") {
            //     this.$refs.recorder.refrsh(0);
            // } else {
            //     this.$refs.recorder.start();
            // }
        },


        changeVoice(voice) {
            this.audio_list = [this.$host + "/api/audio/" + this.source.no + "/" + voice];
        },

        preview() {
            this.$nextTick(() => {
                let img = document.getElementsByClassName("el-image-viewer__img")[0];
                let scale = 1;
                let scale1 = 1;
                let scale2 = 1;
                if (img.height > window.innerHeight * 0.7) {
                    scale1 = window.innerHeight * 0.7 / img.height;
                }
                if (img.width > window.innerWidth * 0.5) {
                    scale2 = window.innerWidth * 0.5 / img.width;
                }
                scale = Math.min(scale1, scale2);
                img.style.transform = `scale(${scale}) rotate(0deg)`
                this.$refs.image.$children[0].$data.transform.scale = scale;

            })
        }
    },
};
</script>
<style scoped></style>
